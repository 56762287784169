import React, { useEffect, useState, createContext, useContext } from 'react';
import { io } from 'socket.io-client';

import './App.css';

const AppContext = createContext<any>({});

const BASE_URL = 'https://whitestat.com';
const PERIOD = 30 * 24 * 60 * 60 * 1000;

const formatNumber = (value: number, digits: number = 2): string => {
  const digitsValue = +(value || 0).toFixed(digits);
  const resultValue = digitsValue.toLocaleString('en-US');

  const [, decimal] = resultValue?.split('.');
  return decimal?.length === 1 ? resultValue + '0' : resultValue;
}

function LoaderComponent() {
  return (
    <div className="text-center">
      {/*<div className="spinner-border" role="status">*/}
      {/*  <span className="sr-only">Loading...</span>*/}
      {/*</div>*/}
    </div>
  );
}

function TitleComponent() {
  return (
    <div className="title text-center">
      <h1>WhiteBIT Souls Statistics</h1>
    </div>
  );
}

interface IStatCardComponent {
  className: string;
  title: string;
  iconClass: string;
  iconColor: string;
  value: number;
  change: number;
  differenceCustom?: string;
}

function StatCardComponent(props: IStatCardComponent) {

  let changeItem = React.createElement(
    'span',
    {className: 'text-muted'},
    props.differenceCustom || 'Unchanged',
  )

  if (!props.differenceCustom && props.change < 0) {
    let changeItemUp = React.createElement(
      'i',
      {className: 'fa fa-arrow-down'},
    )

    changeItem = React.createElement(
      'span',
      {className: 'text-danger font-weight-bold mr-1'},
      changeItemUp,
      ' ',
      props.change.toLocaleString('en-US')
    )
  }

  if (!props.differenceCustom && props.change > 0) {
    let changeItemUp = React.createElement(
      'i',
      {className: 'fa fa-arrow-up'},
    )

    changeItem = React.createElement(
      'span',
      {className: 'text-success font-weight-bold mr-1'},
      changeItemUp,
      ' +',
      props.change.toLocaleString('en-US')
    )
  }

  return(
    <div className={props.className}>
      <div className="stat-card">
        <div className="stat-card__content">
          <p className="text-uppercase mb-1 text-muted">{props.title}</p>
          <h2>{props.value.toLocaleString('en-US')}</h2>
          <div>
            {changeItem}
            {props.differenceCustom ? '' : <span className="text-muted"> since day ago</span>}
          </div>
        </div>
        <div className={`stat-card__icon stat-card__icon--${props.iconColor}`}>
          <div className="stat-card__icon-circle">
            <i className={`fa ${props.iconClass}`}></i>
          </div>
        </div>
      </div>
    </div>
  );
}

function StatusConnectedComponent() {
  return (
    <div className="stat-cards-updated text-center">
        <span className="badge bg-success-subtle text-success-emphasis rounded-pill">
          <i className="fa-solid fa-satellite-dish"></i> Connected to real-time updates
        </span>
    </div>
  );
}

function StatusDisconnectedComponent() {
  return (
    <div className="stat-cards-updated text-center">
        <span className="badge bg-danger-subtle text-danger-emphasis rounded-pill">
          <i className="fa-solid fa-circle-exclamation"></i> Disconnected from real-time updates. Please, reload page!
        </span>
    </div>
  );
}

function StatusLoadingComponent() {
  return (
    <div className="stat-cards-updated text-center">
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}

function StatusComponent(props: any) {
  const { status } = props;

  switch (status) {
    case 'connected':
      return <StatusConnectedComponent />;
    case 'disconnected':
      return <StatusDisconnectedComponent />;
    default:
      return <StatusLoadingComponent />
  }

}


function StatisticsCardsComponent(props: any) {
  const { statistics, price } = props;

  if (!statistics?.updatedAt) {
    return <LoaderComponent />;
  }

  return (
    <div>
      <div className="row">

        <StatCardComponent
          className='col-md-4'
          title='Total Souls'
          iconClass='fa-users'
          iconColor='primary'
          value={statistics?.soulsTotal?.value}
          change={statistics?.soulsTotal?.differences?.day}
        />

        <StatCardComponent
          className='col-md-4'
          title='Holder Souls (10+ WBT)'
          iconClass='fa-hand-holding-dollar'
          iconColor='warning'
          value={statistics?.soulsHolder?.value}
          change={statistics?.soulsHolder?.differences?.day}
        />

        <StatCardComponent
          className='col-md-4'
          title='Launchpad Souls (200+ WBT)'
          iconClass='fa-rocket'
          iconColor='primary'
          value={statistics?.soulsLaunchpadReady?.value}
          change={statistics?.soulsLaunchpadReady?.differences?.day}
        />

      </div>

      <div className="row">

        <StatCardComponent
          className='col-md-4'
          title='Total Hold Amount, WBT'
          iconClass='fa-coins'
          iconColor='success'
          value={+statistics?.soulsHoldAmount?.value?.toFixed(2)}
          change={+statistics?.soulsHoldAmount?.differences?.day?.toFixed(2)}
        />

        <StatCardComponent
          className='col-md-4'
          title='Total Hold Value, USDT'
          iconClass='fa-sack-dollar'
          iconColor='success'
          value={+(statistics?.soulsHoldAmount?.value * price).toFixed(2)}
          change={0}
          differenceCustom={`@ ${price} USDT / WBT`}
        />

        <StatCardComponent
          className='col-md-4'
          title='Soul Drop Balance, WBT'
          iconClass='fa-cube'
          iconColor='success'
          value={+statistics?.soulsDropAmount?.value.toFixed(2)}
          change={+statistics?.soulsDropAmount?.differences?.day.toFixed(2)}
        />

      </div>

    </div>
  );

}

function StatCardsComponent() {
  const { status, price, statistics } = useContext(AppContext);

  return (
    <div>
      <StatusComponent status={status} />
      <StatisticsCardsComponent statistics={statistics} price={price} />
    </div>
  )
}


// TABLE



function SoulVerified() {
  return (
    <div>
      <span className="badge bg-success-subtle text-success-emphasis rounded-pill table-verified">
        <i className="fa-solid fa-circle-check"></i> OK
      </span>
    </div>
  )
}

function SoulNotVerified() {
  return (
    <div>
      <span className="badge bg-danger-subtle text-danger-emphasis rounded-pill table-not-verified">
        <i className="fa-solid fa-circle-xmark"></i> NO
      </span>
    </div>
  )
}

function SoulUpdatedAt(props: any) {
  const getDateTime = (value: string) => {
    return value ? value.replace('T', ' ').replace(/.{0,5}$/, '') + ' UTC' : '—';
  };

  return (
    <div>
        <span className="badge bg-primary-subtle text-primary-emphasis rounded-pill">
          {getDateTime(props.date)}
        </span>
    </div>
  );
}

function SoulRewardClaimed(props: any) {
  if (!props.amount) {
    return (
      <p className="mb-0">—</p>
    );
  }

  return (
    <div>
      <p className="mb-0">{formatNumber(props.amount)} WBT</p>
      <small className="text-muted">{formatNumber((props.amount || 0) * props.price)} USDT</small>
      <SoulUpdatedAt date={props.date} />
    </div>
  );
}

function SoulRewardAvailable(props: any) {
  return (
    <div>
      <p className="mb-0 inline-text">{formatNumber(props.amount || 0)} WBT</p>
      <small className="text-muted inline-text">{formatNumber((props.amount || 0) * props.price)} USDT</small>
    </div>
  );
}

function SoulRewardProgress(props: any) {
  const { date } = props;

  const endAt = new Date(date).getTime();
  const startAt = endAt - PERIOD;

  const percent = +(((Date.now() - startAt) / PERIOD) * 100).toFixed(2);

  return (
    <div className="progress reward-progress">
      <div className="progress-bar bg-success" role="progressbar" style={{ width: `${percent}%` }} aria-valuenow={percent} aria-valuemin={0} aria-valuemax={100}></div>
    </div>
  );
}

function SoulRewardProgressCountdown(props: any) {
  const countdownTo = (date: Date | string): string => {
    date = typeof date === 'string' ? new Date(date) : date;

    const now = new Date();

    let diffInSeconds = Math.floor((date.getTime() - now.getTime()) / 1000);

    if (diffInSeconds < 0) {
      return `0d : 0h : 0m`;
    }

    const days = Math.floor(diffInSeconds / (3600 * 24));
    diffInSeconds -= days * 3600 * 24;

    const hours = Math.floor(diffInSeconds / 3600);
    diffInSeconds -= hours * 3600;

    const minutes = Math.floor(diffInSeconds / 60);

    return `${days}d : ${hours}h : ${minutes}m`;
  };

  return (
    <div className="reward-progress-countdown">
      <small className="text-muted">{countdownTo(props.date)}</small>
    </div>
  );
}


function SoulTableRowComponent(props: any) {
  const { price } = useContext(AppContext);

  const {
    position,
    soulId,

    isVerified,

    holdLevel,
    holdAmount,
    holdAmountUpdatedAt,

    rewardAvailableAmount,
    rewardClaimedAmount,
    rewardClaimedAt,

    nextRewardAmount,
    nextRewardStartAt,
  } = props.data;

  const explorerUrl = `https://explorer.whitechain.io/soul/${soulId}`;
  const claimUrl = 'https://explorer.whitechain.io/address/0x0000000000000000000000000000000000001001/contract/write#claim';

  return (
    <tr key={"soul-key-" + soulId} id={"soul-" + soulId}>
      <td>
        <p className="mb-0">{position}</p>
      </td>
      <td>
        <p className="mb-0">{soulId}</p>
      </td>
      <td>
        {isVerified ? <SoulVerified /> : <SoulNotVerified />}
      </td>
      <td>
        <p className="mb-0">{holdLevel}</p>
      </td>
      <td>
        <p className="mb-0">{formatNumber(holdAmount)} WBT</p>
        <small className="text-muted">{formatNumber(holdAmount * price)} USDT</small>
        <SoulUpdatedAt date={holdAmountUpdatedAt} />
      </td>
      <td>
        <SoulRewardAvailable price={price} amount={rewardAvailableAmount} />
      </td>
      <td>
        <SoulRewardClaimed price={price} amount={rewardClaimedAmount} date={rewardClaimedAt} />
      </td>
      <td>
        { holdAmount ? <SoulRewardProgress date={nextRewardStartAt} /> : '—' }
        { holdAmount ? <SoulRewardProgressCountdown date={nextRewardStartAt} /> : '' }
      </td>
      <td>
        <p className="mb-0">{formatNumber(nextRewardAmount)} WBT</p>
        <small className="text-muted">{formatNumber((nextRewardAmount || 0) * price)} USDT</small>
        { nextRewardStartAt ? <SoulUpdatedAt date={nextRewardStartAt} /> : '' }
      </td>
      <td>
        <div>
          &nbsp;&nbsp;<a href={explorerUrl} target="_blank" rel="noreferrer"><small>Explorer</small></a>&nbsp;&nbsp;
        </div>
        <div>
          &nbsp;&nbsp;<a href={claimUrl} target="_blank" rel="noreferrer"><small>Claim</small></a>&nbsp;&nbsp;
        </div>
      </td>
    </tr>
  );

}



function SoulsTableComponent(props: any) {
  const [filteredSouls, setFilteredSouls] = useState<any>(null);

  const { souls } = useContext(AppContext);

  const fetchFilteredSouls = (soulId: number | null = null) => {
    if (!soulId) {
      setFilteredSouls(null);
      return;
    }

    fetch(`${BASE_URL}/api/v1/souls?soulId=${soulId}`)
      .then((response) => response.json())
      .then((actualData) => setFilteredSouls(actualData.souls))
      .catch((err) => console.log(err.message));
  };

  // const setSoulFilterParams = (soulId: number | null = null) => {
  //   const historyValue: string = soulId ? `?soul=${soulId}` : '/';
  //   window.history?.replaceState(null, '', historyValue);
  // }

  const handleFilerInputChange = (event: any) => {
    const soulId = parseInt(event.target.value, 10);

    const filterSoulId = (!Number.isNaN(soulId) && soulId && soulId > 0 && soulId < 1_000_000) ? soulId : null;

    fetchFilteredSouls(filterSoulId);
    // setSoulFilterParams(filterSoulId);
  };

  // const queryString = window?.location?.search;
  //
  // if (queryString) {
  //   const urlParams = new URLSearchParams(queryString);
  //   const soulId = urlParams.get('soul');
  //
  //   const input = document.getElementById('filter-by-soul-id');
  //
  //   if (soulId && input) {
  //     input.setAttribute('value', soulId);
  //     fetchFilteredSouls(+soulId);
  //   }
  //
  //   if (!soulId && input) {
  //     input.removeAttribute('value');
  //     fetchFilteredSouls(null);
  //   }
  // }

  const rowList = (filteredSouls || souls).map((soul: any) => {
    return <SoulTableRowComponent key={soul.id} data={soul} />
  });

  return (
    <div className="table-wrapper">

      <div className="row">
        <div className="col col-md-10 col-xs-12">
          <h4>{souls.length <= 1 ? 'Filtered Soul' : `Top 100 Souls`}</h4>
        </div>

        <div className="col-md-2 col-xs-12 text-right">
          <input
            type="number"
            placeholder="Filter by Soul ID"
            className={`form-control`}
            id={'filter-by-soul-id'}
            onChange={handleFilerInputChange}
          />
        </div>
      </div>

      <div className="table-responsive-md">
        <table className="table text-center">
          <thead>
          <tr>
            <th>&nbsp;&nbsp;Position</th>
            <th>Soul ID</th>
            <th>Verified</th>

            <th>Hold Level</th>
            <th>Hold Amount</th>

            <th>Available Reward</th>
            <th>Claimed Reward</th>

            <th>Next Reward Progress</th>
            <th>Next Reward</th>

            <th>Links</th>
          </tr>
          </thead>
          <tbody>
            {rowList}
          </tbody>
        </table>
      </div>
    </div>
  );
}


function App() {
  const [status, setStatus] = useState<string>('');
  const [price, setPrice] = useState<number>(0);
  const [statistics, setStatistics] = useState<any>({});
  const [souls, setSouls] = useState<any[]>([]);

  const fetchPriceHandler = () => {
    fetch(`${BASE_URL}/api/v1/prices`)
      .then((response) => response.json())
      .then((actualData) => setPrice(actualData?.price))
      .catch((err) => console.log(err.message));
  };

  const fetchStatisticsHandler = () => {
    fetch(`${BASE_URL}/api/v1/statistics`)
      .then((response) => response.json())
      .then((actualData) => setStatistics(actualData))
      .catch((err) => console.log(err.message));
  };

  const fetchSoulsHandler = () => {
    fetch(`${BASE_URL}/api/v1/souls`)
      .then((response) => response.json())
      .then((actualData) => setSouls(actualData?.souls))
      .catch((err) => console.log(err.message));
  };

  const subscribeEvents = () => {
    const socket = io(BASE_URL, { path: '/events' });

    socket.on('connect', () => setStatus('connected'));
    socket.on('disconnect', () => setStatus('disconnected'));
    socket.on('connect_error', () => setStatus('disconnected'));

    socket.on('prices_changed', (price: number) => setPrice(price));
    socket.on('statistics_changed', (message: string) => setStatistics(JSON.parse(message)));
    socket.on('souls_top_list_changed', (message: string) => setSouls(JSON.parse(message)));

    return { socket };
  };

  useEffect(() => {
    fetchPriceHandler();
    fetchStatisticsHandler();
    fetchSoulsHandler();

    const { socket } = subscribeEvents();

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div className="main-content">
      <div className="container">

        <AppContext.Provider value={{ status, setStatus, price, setPrice, statistics, setStatistics, souls, setSouls }}>
          <TitleComponent />
          <StatCardsComponent />
          <SoulsTableComponent />
        </AppContext.Provider>

      </div>
    </div>
  );
}

export default App;
